// @flow
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import "./styles/style.scss";
import "./App.scss";
import App from "./preview/App";
import BrowserNotSupported from "./components/BrowserNotSupported/BrowserNotSupported";
import RootNodeProvider from "./helper/RootNodeProvider";
import {createRoot} from "react-dom/client";
import store from "./store";
import {Provider} from "react-redux";

const isInternetExplorer = !!document.documentMode;

const render = () => {
	const previewRootNode = createRoot(RootNodeProvider.getPreviewRootNode());
	previewRootNode.render(
		<Provider store={store}>
			{isInternetExplorer
				? <BrowserNotSupported/>
				: <App/>
			}
		</Provider>
	);
};

render();
